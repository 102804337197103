
h4 {
  margin-bottom: 6px;
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

p {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400p;
  margin: 0;
}

.container {
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.07);
  width: 100%;
  min-width: 260px;
  max-width: 500px;
  padding: 21px 28px;
  margin: 13px 0!important;
}

.logo {
  max-width: 200px
}

.available-seats {
  color: gray;
}

.header {
  background: white;
  justify-content: center;
  padding: 8px 0;
}

.padding {
  padding: 0 24px;
  max-width: 500px;
  width: 100%;
}

.MuiOutlinedInput-input {
  padding: 11.5px 14px!important;
}



/*

p {
  padding: 0 18px;
  color: #605d5e;
  line-height: 23px;
  word-spacing: 1.2px;
}

.MuiButton-containedPrimary {
  background-color: white!important;
  color: #373334!important;
  margin-top: 18px!important;
  min-width: 250px!important;
  padding: 10px 16px!important;
}

.amount-input {
  padding: 18px;
}

.amount-input .MuiTextField-root {
  width: 200px;
}

.MuiInput-input {
  font-size: 32px!important;
}

.MuiTextField-root {
  margin-bottom: 16px!important;
} */